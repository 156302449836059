.indexPageStartANewToken {
    font-size: 2rem;
    cursor: pointer;
}

.indexPageStartANewToken:hover {
    text-decoration: underline;
    /* bold */
    font-weight: bold;
}

.indexPageBody {
    max-width: "800px";
    margin: "1rem auto";
    width: "100%";
    padding: "1rem";
}

.indexPageSearchBox {
    display: flex;
    /* column */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

.indexPageSearchBox input {
    background-color: rgba(var(--bs-warning-rgb));
    color: black;
}

/* placeholder color */
.indexPageSearchBox input::placeholder {
    color: #999;
}

/* on focus */
.indexPageSearchBox input:focus {
    background-color: rgba(var(--bs-warning-rgb));
    color: black;
}

.indexPageSearchBox.searchInput {
    width: "100%";
    padding: "0.5rem";
    border: "1px solid #ccc";
    border-radius: "5px";
    margin-right: "1rem";
}

/* searchButton */
.indexPageSearchBox.searchButton {
    padding: "0.5rem 1rem";
    background-color: "#007bff";
    color: "#fff";
    border: "none";
    border-radius: "5px";
    cursor: "pointer";
}

.indexSelectWrap {
    display: flex;
    flex-wrap: wrap;
    /* row */
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.indexSelectLabel {
    font-size: 0.9rem;
}

.indexSelectItem {
    padding: 0.1rem 0.3rem;
    margin-left: 0.2rem;
    cursor: pointer;
}

.indexSelectItem.selected {
    background-color: #6edff6;
    color: black;
}


@media (max-width: 768px) {
    .only-pc {
        display: none;
    }

    .only-mobile {
        display: block;
    }
}

@media (min-width: 769px) {
    .only-pc {
        display: block;
    }

    .only-mobile {
        display: none;
    }
}

.center {
    text-align: center;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}
.align-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.cursor {
    cursor: pointer;
}
.padding-left {
    padding-left: 1rem;
}

.padding {
    padding: 1rem;
}
.margin-left {
    margin-left: 1rem;
}

.margin-top{
    margin-top: 1rem;
}
.justify-between {
    justify-content: space-between;
}

.tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.tabs>.active{
    color: #f59a23;
    border-bottom: 2px solid #f59a23;
}